import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { ApplicationEditions } from './edition.tsx';
import type { BaseUrl, CloudId, CloudName, AccountId, ActivationId } from './general.tsx';
import type { ApplicationLicenseStates } from './license-state.tsx';
import type { ProductPermissionGates } from './product-permission-gates.tsx';

export type ApplicationPermissions = {
	readonly hasCoreAccess: boolean;
	readonly hasSoftwareAccess: boolean;
	readonly hasServiceDeskAccess: boolean;
	readonly hasCustomerServiceAccess: boolean;
	readonly hasProductDiscoveryAccess: boolean;
};

/**
 * @typedef EntitlementDetails Information about entitlement and current edition properties {@link https://developer.atlassian.com/platform/commerce-cloud-platform/overview/ccp-primitives/#entitlement}
 * @type {object}
 * @property {string} entitlementId       Billing entitlementId is ID which uniquely identifies product in billing ecosystem
 * @property {number} trialEndTime        Exists only if site is in trial and marks the end of the trial
 * @property {number} unitCount           Number of users allowed on site under current edition
 * @property {number} limit               Media storage limit for site under current edition = doesn't exist if unlimited is true
 * @property {number} unlimited           Set if media storage under current edition is unlimited - doesn't exist if limit is set
 * @property {number} billingSourceSystem What billing system is used. Either `CCP` or undefined. undefined implies HAMS.
 */
export type EntitlementDetails = {
	readonly entitlementId?: string;
	readonly trialEndTime?: number;
	readonly unitCount?: number;
	readonly billingSourceSystem?: 'CCP';
} & (
	| {
			readonly unlimited?: true;
	  }
	| {
			readonly limit?: number;
	  }
);

export const populateApplicationPermissions = (
	overrides: Partial<ApplicationPermissions> = {},
	defaultPermission = false,
): ApplicationPermissions => ({
	hasCoreAccess: defaultPermission,
	hasSoftwareAccess: defaultPermission,
	hasServiceDeskAccess: defaultPermission,
	hasCustomerServiceAccess: defaultPermission,
	hasProductDiscoveryAccess: defaultPermission,
	...overrides,
});

export type LicensedProducts = {
	[key: string]: boolean;
};

export type ProductCrossSellConfig = 'enabled' | 'disabled' | 'unknown';

// WARNING: this means "global Jira admin", not "site admin"! A "site admin" is someone that is
// allowed to make changes to the site that will affect billing, such as granting licenses to users
// or installing new applications. A "global Jira admin" is a user that can modify global Jira
// permissions or global entities like schemes.
// Consider using `isSiteAdmin` instead.
export type SiteAdminStatus = 'admin' | 'non-admin' | 'unknown';

// Make environment names explicit. These need to be kept in sync with
// https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-utils-core/src/main/java/com/atlassian/jira/micros/MicrosEnvironment.java#36
// But they should not change frequently. Also, we need to account for the possibility that
// the back-end returns null, or the front-end is broken and returns an empty string.
export type Environment = 'prod' | 'staging' | 'dev' | 'local';
// This switch statement exists because Flow can't infer types from an array. Sigh.
export const toEnvironment = (value: string | null): Environment | null => {
	switch (value) {
		case 'dev':
			return 'dev';
		case 'staging':
			return 'staging';
		case 'prod':
			return 'prod';
		case 'local':
			return 'local';
		default:
			return null;
	}
};

/**
 * 🚨 Do not add ReadOnly<> to TenantContext.
 * It will make the return type of useTenantContext as `(create: () => MaybeCleanUpFn, inputs: ?ReadOnlyArray<mixed>) => void`
 */
export type TenantContext = {
	appEditions: ApplicationEditions;
	appPermissions: ApplicationPermissions; // This returns false for implicit license of JC,
	applicationKey: string | null;
	appUpdatesAvailable: number | null;
	areProductSuggestionsEnabled: boolean;
	atlassianAccountId: AccountId | null;
	baseUrl: BaseUrl;
	cloudId: CloudId;
	// make `orgId` mandatory when cleaning up `issue_view_prevent_unnecessary_orgid_query`
	orgId?: string;
	activationId: ActivationId;
	cloudName: CloudName;
	devopsProjectSignupSource: string | null;
	environment: Environment | null;
	fabricActivityUrl: string | null;
	fabricNotificationLogUrl: string | null;
	fabricPfDirUrl: string | null;
	firstActivationDateMs: number | null;
	isAdmin: boolean;
	isAnonymous: boolean;
	isDevMode: boolean;
	isDevopsProjectCreationScenario: boolean;
	isMarketingOptOut: boolean; // This returns true for Sandboxes instances, false otherwise.
	isSiteAdmin: boolean;
	languageTag: string | null | undefined;
	licenseStates: ApplicationLicenseStates; // This returns ACTIVE for implicit license of JC,
	licensedProducts: LicensedProducts; // This returns false for implicit license of JC,
	locale: Locale;
	loggedInUser: string | null;
	productEntitlementDetails: Record<string, EntitlementDetails> | null; // map of licensed product keys and entitlement details, for more about entitlement details {@see EntitlementDetails}
	productCrossSellConfig: ProductCrossSellConfig;
	productPermissionGated: ProductPermissionGates;
	siteAdminStatus: SiteAdminStatus;
	userFullname: string | null;
	/**
	 * @deprecated use getXsrfToken from '@atlassian/jira-platform-xsrf-token' instead
	 */
	xsrfToken: string | null;
	hasDlpEnabled: boolean;
	avpWorkspaceId: string | null;
	[key: number]: null;
	/**
	 * 🐶 `[number]: null`, is to make sure flow doesn't take the return value of useTenantContext as array.
	 * ie. `const [{ tenantContext }] = useTenantContext` should no longer be valid.
	 * Ref: https://flow.org/en/docs/types/objects/
	 * Quote: It is the programmer’s responsibility to ensure the access is safe, as with arrays
	 * 🚨 Note that this will cause flow to NOT allow spread operator. Object.assign should be used instead.
	 */
};
