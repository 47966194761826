import React, { type ReactNode, Component, useEffect } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { TrackerWithoutCallbacksProps } from '../../common/types.tsx';
import { ExperienceTracker, defaultProps } from '../tracker-base/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TrackerWithoutCallbacksProps };
type ResultSpecifierTrackerChildProps = {
	hasSucceeded?: boolean;
	hasFailed?: boolean;
	hasAborted?: boolean;
	children: ReactNode;
	onExperienceSuccess: () => void;
	onExperienceFailure: () => void;
	onExperienceAbort: () => void;
};

/**
 * A component designed to monitor the outcomes of specific operations (success, failure, abort) and execute corresponding actions. Ideal for tracking operation results and facilitating immediate response.
 */
// eslint-disable-next-line jira/react/no-class-components
export class ResultDeclaredTrackerChildOld extends Component<ResultSpecifierTrackerChildProps> {
	static defaultProps = {
		hasSucceeded: false,
		hasFailed: false,
		hasAborted: false,
		children: null,
	};

	componentDidMount() {
		this.postRenderChecks();
	}

	componentDidUpdate() {
		this.postRenderChecks();
	}

	postRenderChecks() {
		const {
			onExperienceSuccess,
			onExperienceFailure,
			onExperienceAbort,
			hasSucceeded,
			hasFailed,
			hasAborted,
		} = this.props;
		if (hasFailed) {
			onExperienceFailure();
		} else if (hasAborted) {
			onExperienceAbort();
		} else if (hasSucceeded) {
			onExperienceSuccess();
		}
	}

	render() {
		return this.props.children;
	}
}

export const ResultDeclaredTrackerChildNew = ({
	onExperienceSuccess,
	onExperienceFailure,
	onExperienceAbort,
	hasSucceeded = false,
	hasFailed = false,
	hasAborted = false,
	children = null,
}: ResultSpecifierTrackerChildProps) => {
	useEffect(() => {
		if (hasFailed) {
			onExperienceFailure();
		} else if (hasAborted) {
			onExperienceAbort();
		} else if (hasSucceeded) {
			onExperienceSuccess();
		}
	}, [
		hasSucceeded,
		hasFailed,
		hasAborted,
		onExperienceSuccess,
		onExperienceFailure,
		onExperienceAbort,
	]);

	return children;
};

export const ResultDeclaredTrackerChild = componentWithFG(
	'jfp-magma-taskfail-event-multi-firing-board-fix',
	ResultDeclaredTrackerChildNew,
	ResultDeclaredTrackerChildOld,
);

export const ExperienceSuccessTracker = ({ children, ...props }: TrackerWithoutCallbacksProps) =>
	__SERVER__ ? (
		<>{children}</>
	) : (
		<ExperienceTracker {...props}>
			{({ onExperienceSuccess, onExperienceFailure, onExperienceAbort }) => (
				<ResultDeclaredTrackerChild
					hasSucceeded
					onExperienceSuccess={onExperienceSuccess}
					onExperienceFailure={onExperienceFailure}
					onExperienceAbort={onExperienceAbort}
				>
					{children}
				</ResultDeclaredTrackerChild>
			)}
		</ExperienceTracker>
	);
ExperienceSuccessTracker.defaultProps = defaultProps;
export const ExperienceFailureTracker = ({ children, ...props }: TrackerWithoutCallbacksProps) =>
	__SERVER__ ? (
		<>{children}</>
	) : (
		<ExperienceTracker {...props}>
			{({ onExperienceSuccess, onExperienceFailure, onExperienceAbort }) => (
				<ResultDeclaredTrackerChild
					hasFailed
					onExperienceSuccess={onExperienceSuccess}
					onExperienceFailure={onExperienceFailure}
					onExperienceAbort={onExperienceAbort}
				>
					{children}
				</ResultDeclaredTrackerChild>
			)}
		</ExperienceTracker>
	);
ExperienceFailureTracker.defaultProps = defaultProps;
export const ExperienceAbortTracker = ({ children, ...props }: TrackerWithoutCallbacksProps) =>
	__SERVER__ ? (
		<>{children}</>
	) : (
		<ExperienceTracker {...props}>
			{({ onExperienceSuccess, onExperienceFailure, onExperienceAbort }) => (
				<ResultDeclaredTrackerChild
					hasAborted
					onExperienceSuccess={onExperienceSuccess}
					onExperienceFailure={onExperienceFailure}
					onExperienceAbort={onExperienceAbort}
				>
					{children}
				</ResultDeclaredTrackerChild>
			)}
		</ExperienceTracker>
	);
ExperienceAbortTracker.defaultProps = defaultProps;
export type ResultProps = {
	hasSucceeded: boolean;
	hasFailed: boolean;
	hasAborted: boolean;
} & TrackerWithoutCallbacksProps;
export const ExperienceResultFromPropsTracker = ({
	hasSucceeded,
	hasFailed,
	hasAborted,
	children,
	...trackerProps
}: ResultProps) =>
	__SERVER__ ? (
		<>{children}</>
	) : (
		<ExperienceTracker {...trackerProps}>
			{({ onExperienceSuccess, onExperienceFailure, onExperienceAbort }) => (
				<ResultDeclaredTrackerChild
					hasSucceeded={hasSucceeded}
					hasFailed={hasFailed}
					hasAborted={hasAborted}
					onExperienceSuccess={onExperienceSuccess}
					onExperienceFailure={onExperienceFailure}
					onExperienceAbort={onExperienceAbort}
				>
					{children}
				</ResultDeclaredTrackerChild>
			)}
		</ExperienceTracker>
	);
ExperienceResultFromPropsTracker.defaultProps = {
	hasSucceeded: false,
	hasFailed: false,
	hasAborted: false,
	...defaultProps,
};
