import { cacheableTypes } from '../../const.tsx';
import type { CHRReporterSummary, CHRReporterSummaryEntry } from '../../types.tsx';
import { CHRSummary } from '../chrsummary/index.tsx';
/**
 * Serves as a tool for capturing and analyzing resource loading performance metrics. By distinguishing between preloaded and all resources, it offers insights into optimizing resource utilization and loading strategies, contributing to enhanced web performance.
 */
export class CHRReporter {
	preloaded = new CHRSummary();

	all = new CHRSummary();

	ssrMark: number | null = null;

	constructor(ssrMark: number | null) {
		this.ssrMark = ssrMark;
	}

	add(item: PerformanceResourceTiming) {
		if (item.encodedBodySize === 0) {
			return;
		}

		if (
			item.initiatorType === 'link' &&
			cacheableTypes.includes(item.initiatorType) &&
			(this.ssrMark === null || item.startTime < this.ssrMark)
		) {
			this.preloaded.add(item);
		}
		this.all.add(item);
	}

	static round(n: number) {
		return Math.round(n * 10000) / 10000;
	}

	private makePayload(summary: CHRSummary): CHRReporterSummaryEntry {
		const bundlesLoadType = {
			mem: CHRReporter.round(summary.bundles[CHRSummary.MEMORY_KEY] / summary.bundlesCount),
			disk: CHRReporter.round(summary.bundles[CHRSummary.DISK_KEY] / summary.bundlesCount),
			net: CHRReporter.round(summary.bundles[CHRSummary.NETWORK_KEY] / summary.bundlesCount),
		};
		const bundlesRatio = CHRReporter.round(
			(summary.bundles[CHRSummary.MEMORY_KEY] + summary.bundles[CHRSummary.DISK_KEY]) /
				summary.bundlesCount,
		);
		const sizeLoadType = {
			mem: CHRReporter.round(summary.size[CHRSummary.MEMORY_KEY] / summary.sizeTotal),
			disk: CHRReporter.round(summary.size[CHRSummary.DISK_KEY] / summary.sizeTotal),
			net: CHRReporter.round(summary.size[CHRSummary.NETWORK_KEY] / summary.sizeTotal),
		};
		const sizeRatio = CHRReporter.round(
			(summary.size[CHRSummary.MEMORY_KEY] + summary.size[CHRSummary.DISK_KEY]) / summary.sizeTotal,
		);
		return {
			bundles: {
				ratio: bundlesRatio,
				count: summary.bundlesCount,
				loadType: bundlesLoadType,
			},
			size: {
				ratio: sizeRatio,
				total: summary.sizeTotal,
				loadType: sizeLoadType,
			},
		};
	}

	get(): CHRReporterSummary | null {
		if (this.all.bundlesCount === 0) {
			return null;
		}
		return {
			preloaded: this.makePayload(this.preloaded),
			all: this.makePayload(this.all),
		};
	}
}
