import setupUFOGlobalErrorHandler from '@atlaskit/react-ufo/global-error-handler';
import { installErrorHandler } from '@atlaskit/report-error';
import { installGlobalHandler } from '@atlassian/jira-errors-handling/src/utils/sentry/initialization.tsx';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';

import { platformErrorHandler } from '@atlassian/jira-platform-errors-handling/src/platformErrorHandler.tsx';

if (!killswitch('fe_platform_error_tracking_enabled_sentry')) {
	installGlobalHandler();
}

installErrorHandler(platformErrorHandler);

setupUFOGlobalErrorHandler();
