import React, { type ComponentType } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { ErrorBoundaryFlagProps, ErrorFlagRendererProps } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ErrorBoundaryFlagProps } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ErrorFlagRendererLazy: ComponentType<ErrorFlagRendererProps> = lazy(
	() => import(/* webpackChunkName: "async-error-flag-renderer" */ './ui/index.tsx'),
);

/**
 * @deprecated Do not use directly, instead use `JSErrorBoundary` in
 * `@atlassian/jira-error-boundaries`, which will log appropriate errors to
 * Sentry/SFX/Splunk. See https://hello.atlassian.net/wiki/spaces/JFP/pages/1890192830/Jira+Playbook+Uncaught+error+handling
 */
export const ErrorFlagRenderer = (props: ErrorFlagRendererProps) => {
	const packageDetails = {
		id: 'asyncErrorFlagComponent',
		packageName: 'jiraErrorBoundary',
	};

	return (
		<ErrorBoundary {...packageDetails}>
			<ReportErrors teamName="magma" {...packageDetails}>
				<Placeholder name="async-error-flag-renderer" fallback={null}>
					<ErrorFlagRendererLazy {...props} />
				</Placeholder>
			</ReportErrors>
		</ErrorBoundary>
	);
};

const renderErrorFlag = ({ error }: { error: Error }) => (
	<ErrorFlagRenderer id="error-boundary" error={error} />
);

/**
 * An error boundary that will render an atlaskit flag when a render exception
 * is thrown.
 *
 * @deprecated Do not use directly, instead use `JSErrorBoundary` in
 * `@atlassian/jira-error-boundaries`, which will log appropriate errors to
 * Sentry/SFX/Splunk. See https://hello.atlassian.net/wiki/spaces/JFP/pages/1890192830/Jira+Playbook+Uncaught+error+handling
 */
export const ErrorBoundaryFlag = ({ children, ...props }: ErrorBoundaryFlagProps) => (
	<ErrorBoundary {...props} render={renderErrorFlag}>
		{children}
	</ErrorBoundary>
);

ErrorBoundaryFlag.defaultProps = {
	extraEventData: {},
	id: undefined,
};
